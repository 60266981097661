<template lang="html">
  <section class="results">
    <div>
      <hero-global
        img="/img/clarity/clarity-1.svg"
        title="Búsqueda"
        subtitle="Búsqueda en CERMI"
      />
      <div class="container">
        <div class="container-search">
          <input
            type="text"
            placeholder="Buscar..."
            id="searchTool"
            v-model="textSearch"
            title="Escribe algo para buscar"
          />
          <button
            type="button"
            class="btn-search"
            @click="sendSearch"
            aria-label="Click para buscar"
            title="Click para buscar"
          ></button>
        </div>
      </div>
      <section class="container container-results">
        <div class="total-container"  v-if="totalResults == 0">
          <p class="title">Lo sentimos, no hemos encontrado resultados</p>
        </div>
        <a
          class="result-el"
          :href="item.link"
          v-for="(item, key) in results"
          :key="key"
          target="_blank"
        >
          <div class="content">
            <img
              v-if="
                item.pagemap.metatags[0].hasOwnProperty('og:image') &&
                item.pagemap.metatags[0]['og:image'] != 'null'
              "
              :src="item.pagemap.metatags[0]['og:image']"
              :alt="item.pagemap.metatags[0]['ogtitle']"
            />
            <p class="title" v-html="item.htmlTitle"></p>
            <p v-html="item.htmlSnippet"></p>
          </div>
        </a>
      </section>
      <paginator
        class="m-bottom-5-rem"
        v-if="results"
        :pageActual="page"
        :totalItems="pagesResult"
        @clicked="changePages"
        @changed="changePages"
      />
    </div>
  </section>
</template>

<script lang="js">
import HeroGlobal from "@/components/Global/HeroGlobal";
import { contentStore } from '@/stores/contents'
import Paginator from "@/components/Global/PaginationGlobal";
const store = contentStore();
export default {
  name: 'results',
  components: {
    HeroGlobal,
    Paginator
  },
  props: [],
  setup() {
      return {
        store,

      }
  },
  mounted() {
    if (this.$route.query.key) {
      this.gotoSearch()
      this.textSearch = this.$route.query.key
    }

  },
  data() {
    return {
      results: null,
      totalResults: null,
      pagesResult: null,
      page: 1,
      start:1,
    }
  },
  methods: {
    gotoSearch() {
      let t = this;
      this.store.loadResultGoogle(
        {
          start: this.start,
          textSearch: this.$route.query.key
        }
      ).then(function (result) {
        t.results = result.items
        if (result.searchInformation.totalResults > 100) {
            t.totalResults = 100
        } else {
             t.totalResults = result.searchInformation.totalResults
          }
          t.pagesResult =  Math.ceil(t.totalResults / 10)
           
        });
    },
    sendSearch: function () {
      this.$router.push({  query: { key: this.textSearch } });
    },
    changePages(item, page) {
     this.page = item
      let t = this;
      this.start = item * 10 + 1 - 10
      this.gotoSearch()
        setTimeout(function () {
          scroll({
            behavior: "smooth",
            top: 0,
          });
        }, 200);
      },
  },
  computed: {

  },
  watch: {
  "$route.query.key"() {
    this.gotoSearch()
    this.start = 1
  },
}
}
</script>
